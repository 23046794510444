import React from 'react'
import parse from 'html-react-parser';
import Spacing from '../Spacing'
import Div from '../Div';
import Button from '../Button';

export default function SectionHeading({title, btnLink,src, btnText, variant, children}) {
  return (
    <Div className={variant ? `cs-section_heading ${variant}` : `cs-section_heading cs-style1`}>
      {/* <h3 className="cs-section_subtitle"> -- {parse(subtitle)}</h3> */}
      <h2 className="cs-section_title ">{parse(title)}</h2>
      {children}
      <Spacing lg='45' md='20'/>
      <div className='w-full h-full flex justify-center items-center lg:translate-y-[50%] ' >
      {src && ( <img src={src} alt='sectionImage' className='object-contain '/>)}

      </div>
      {btnText && (
        <div className='mb-3 md:mb-0'>
          <Button btnLink={btnLink} btnText={btnText}/>
        </div>
      )}
    </Div>
  )
}
