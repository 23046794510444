import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { portfolioDetailsData } from "../../data";

export default function PortfolioDetailsPage() {


  pageTitle("Portfolio Details");
  const { portfolioDetailsId } = useParams();
  const [portfolio] = useState(
    portfolioDetailsData[portfolioDetailsId] || {}
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <div className="flex bg-[#052443]  justify-center items-center rounded-lg h-full w-full">
        <img
          src={portfolio.image}
          alt="Details"
          className="w-[50%] [h-50%] "
        />
        </div>
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title={portfolio.title || "Project Title"}
              subtitle={"Overview"}
            >
              
              {/* <p>Category : {portfolio.category}</p> */}
              <Spacing lg="40" md="20" />
              <p>{portfolio.description}</p>
              <Spacing lg="40" md="20" />
              <Div className="w-[100%] flex justify-center items-center md:block md:w-[50%] ">
                {portfolio.liveLink ? (
                  <a
                    href={portfolio.liveLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <p className="text-blue-500 underline">Check out the live website</p> */}
                      <button className="bg-[#257cd9] relative px-3 py-2 rounded-md w-full text-white">
                        Check out the live website
                      </button>
                  </a>
                ) : (
                  <p>No Live Link Available</p>
                )}
              </Div>{" "}
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Key Features : </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              {portfolio.keyFeatures &&
                Object.entries(portfolio.keyFeatures).map(
                  ([title, description], index) => (
                    <Div className="col-10" key={index}>
                      <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                        {title}:
                      </h3>
                      <p className="cs-m0">{description}</p>
                      <Spacing lg="30" md="30" />
                    </Div>
                  )
                )}
            </Div>
          </Div>
        </Div>
        {/* <Spacing lg="65" md="10" /> */}
      </Div>
      {/* <Spacing lg="145" md="80" /> */}
      <Div className="container mt-9">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Book a call"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
