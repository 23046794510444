import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Pagination from '../Pagination';
import PostStyle2 from '../Post/PostStyle2';
import Div from '../Div';
import Spacing from '../Spacing';
const postData = [
  {
    thumb: '/images/blog/Des_7_11zon.png',
    title: 'Decoding the Impact of Social Media Analytics on Your Digital Strategy',
    subtitle:"In digital marketing, understanding social media analytics is crucial. These insights help organizations make informed decisions and track their online presence.",
    categoryHref: '/blog',
    href: '/blog/1',
  },
  {

    thumb: '/images/blog/ssm_1_11zon.jpg',
    title: 'Unleashing the Power of Social Media Marketing',
    subtitle:
      "Social media has transformed how businesses communicate with their audiences in the digital age. Companies can utilize social media to communicate with their customers.",
    categoryHref: '/blog',
    href: '/blog/2',
  },

  {
    thumb: '/images/blog/instaBlog_11_11zon.png',
    title: 'Dominate the Digital World with Instagram Marketing',
    subtitle:
    "In today's digital age, social media has emerged as a crucial tool for businesses seeking to connect with their target audience and increase brand awareness. Among the several platforms accessible, Instagram stands out as a force for visual content and interaction. With over a billion active users, using Instagram for marketing can be transformational for businesses of all kinds. This comprehensive guide will take you through the intricacies of Instagram marketing, giving you invaluable insights and ideas to improve your social media game.",
    categoryHref: '/blog',
    href: '/blog/4',
  },
  {
    thumb: '/images/blog/D_fb_6_11zon.png',
    title: 'Dominate Your Online Presence with Effective Facebook Marketing Techniques',
    subtitle:
    "In this digital era, Facebook has become a formidable tool for brands wishing to connect with their audience. The network has over 2.8 billion active monthly users. It provides a huge chance for businesses to dominate their markets with smart Facebook marketing. In this post, we'll look at efficient ideas and approaches for using Facebook marketing to catapult your firm to the top of its industry.",
    categoryHref: '/blog',
    href: '/blog/5',
  },
  {
    thumb: '/images/blog/Insta_10_11zon.png',
    title: 'Transform your online presence with effective social media management',
    subtitle:
    "Are you looking to improve your online presence and engage your target audience more effectively? It is time to focus on social media management. Today's digital world requires effective social media management. It is critical for business success and brand visibility. Let's look at how proper social media management can help you accomplish your online goals.",
    categoryHref: '/blog',
    href: '/blog/3',
  },
  // {
  //   thumb: '/images/insta.png',
  //   title: 'Unleashing the Potential of LinkedIn for Your Marketing Strategy',
  //   subtitle:
  //   "Today's digital world requires businesses to use social media. Doing so is critical for reaching a larger audience and boosting marketing efforts. Many social media sites are accessible. Among them, LinkedIn stands out. It is a valuable tool for effective marketing. LinkedIn focuses on professionals. Its vast network offers rare chances for businesses to connect, interact, and promote their brands. In this post, we'll look at how to use LinkedIn for efficient marketing and make the most of this platform.",
  //   categoryHref: '/blog',
  //   href: '/blog/6',
  // },
  {
    thumb: '/images/blog/linkedin_12_11zon.png',
    title: 'Unleashing the Potential of LinkedIn for Your Marketing Strategy',
    subtitle:
    "Today's digital world requires businesses to use social media. Doing so is critical for reaching a larger audience and boosting marketing efforts. Many social media sites are accessible. Among them, LinkedIn stands out. It is a valuable tool for effective marketing. LinkedIn focuses on professionals. Its vast network offers rare chances for businesses to connect, interact, and promote their brands. In this post, we'll look at how to use LinkedIn for efficient marketing and make the most of this platform.",
    categoryHref: '/blog',
    href: '/blog/7',
  },
  {
    thumb: '/images/blog/comprehensive_2_11zon.png',
    title: 'The Power of Social Media Advertising: A Comprehensive Guide',
    subtitle:
    "Today, in the digital age, social media ads are essential. Firms use them to reach and engage their target audience. Businesses can use the right tactics. They will improve their ads and gain big benefits. This post will examine the key tactics for successfully mastering social media advertising techniques.",
    categoryHref: '/blog',
    href: '/blog/8',
  },
  {
    thumb: '/images/blog/newfrontier_15_11zon.png',
    title: 'New Frontiers: Social Media Trends Redefining Digital Interactions',
    subtitle:
    "Today's digital world is fast-paced. Social media platforms are always changing and setting new trends. Staying on top of the latest social media trends is critical. It helps you stay relevant and engage your audience well. This post will look at ways to remain ahead of the curve and make the most of social media's ever-changing landscape.",
    categoryHref: '/blog',
    href: '/blog/9',
  },
{
  thumb: '/images/blog/content_calender_3_11zon.png',
  title: 'Effective Strategies for Crafting a Successful Content Calendar',
  subtitle:
  "In digital marketing, a strong content calendar is the secret weapon. Successful organizations use it to plan and execute their content strategy. Whether you're a freelancer, a small business owner, or a marketer, a content calendar is vital. It helps you stay organized and boosts your material's impact. Let's look at the important steps for developing a robust content calendar that will improve your content marketing game.",
  categoryHref: '/blog',
  href: '/blog/10',
},
{
  thumb: '/images/blog/maximizeTools_14_11zon.png',
  title: 'Maximize Your Online Presence With These Social Media Tools',
  subtitle:
  "In today's digital age, social media has become an integral component of marketing strategy. To navigate social media's ever-changing environment well, marketers need the right tools. These tools help them streamline their efforts, track performance, and talk to their target audience. In this blog, we will look at some critical social media tools that every marketer should consider adding to their toolkit.",
  categoryHref: '/blog',
  href: '/blog/11',
},
{
  thumb: '/images/blog/pinterest_16_11zon.png',
  title: '  Unlocking Success: Innovative Pinterest Marketing Strategies',
  subtitle:
  "Today, in the digital age, social media platforms are vital tools. They help organizations reach their target consumers. Among these platforms, Pinterest stands out. It's a visual discovery engine. It lets users explore and bookmark ideas based on their interests. To use Pinterest for marketing, businesses need innovative techniques. These techniques must set them apart from the competition. In this post, we'll look at some clever ways to strengthen your Pinterest marketing. They will increase engagement and conversions.",
  categoryHref: '/blog',
  href: '/blog/12',
},
{
  thumb: '/images/blog/creftingCompilling_5_11zon.png',
  title: 'Crafting Compelling Social Media Content That Drives Engagement',
  subtitle:
"Today's digital age has made social media crucial. It's a platform for businesses to engage with their audience and promote their brand. One of the most important parts of a successful social media strategy is providing interesting content. It must intrigue your target audience. In this blog post, we'll look at the power of social media content and how it may help your brand's online visibility.",
  categoryHref: '/blog',
  href: '/blog/13',
},
{
  thumb: '/images/blog/boostYouSocialMedia_1_11zon.png',
  title: 'Boost Your Social Media Engagement with These Tactics',
  subtitle:
  "In the digital age, social media has become a great tool. It helps organizations and people talk to their target audience. However, the web has more and more content. It can be hard to tell it apart and engage with it. This post will review numerous ways to improve social media engagement tactics and increase your online presence.",
  categoryHref: '/blog',
  href: '/blog/14',
},
{
  thumb: '/images/blog/ultimateGuide_19_11zon.png',
  title: ' The Ultimate Guide to Successful Online Advertising Campaigns',
  subtitle:
  "Online Advertising Overview In contemporary global business prospects, the online regimen is one of the effective mechanisms that help businesses attain their target audience by strengthening brand awareness. Yet, the digital marketing world is constantly changing, so navigating your online advertising campaigns to success can be challenging. This ultimate guide intends to help you with strategies and tips for online ads that can get you the maximum result.",
  categoryHref: '/blog',
  href: '/blog/15',
},
{
  thumb: '/images/blog/masteringSocialMediaMarketing_13_11zon.png',
  title: 'Mastering Social Media Marketing: Tips and Tricks for Success',
  subtitle:
  "With the emergence of the digital age, social media marketing is an essential skill for all businesses and individuals to master. This is why you must know how to make the most of these channels, as social media platforms give you the power to reach a global audience. Let's go through a few tips to make your path smoother in social media marketing and achieve your goals in the minimal period this blog will lead you towards.",
  categoryHref: '/blog',
  href: '/blog/16',
},
{
  thumb: '/images/blog/secreateOfContentC_17_11zon.jpeg',
  title: 'Unveiling the Secrets of Content Marketing: How to Create Compelling Content',
  subtitle:
"Today, in the digital era, content marketing is considered a lifeline to attract and convert an audience. Great content is essential to cementing your marketing strategies and extensions that speak directly to your target audience. This article will teach you how to create engaging content that reshapes content to attract the audience and positively impact brand visibility.",
  categoryHref: '/blog',
  href: '/blog/17',
},
{
  thumb: '/images/blog/crackingThe_4_11zon.jpeg',
  title: 'Cracking the Code of Search Engine Optimization (SEO): Strategies That Work',
  subtitle:
  "In the digital era, online visibility is the one thing that can make or break a business, and learning the art of Search Engine Optimization (SEO) is the key. Search engines continually update their algorithms, and getting ahead of them is a real challenge. In this post, I have compiled a list of SEO strategies that will help you improve your site's ranking and drive more organic traffic.",
  categoryHref: '/blog',
  href: '/blog/18',
},
{
  thumb: '/images/blog/tenProven_18_11zon.jpeg',
  title: '10 Proven Digital Marketing Strategies to Boost Your Online Presence',
  subtitle:
  "In today's digital world, having a solid online presence is vital for businesses to reach their target audience and stay ahead of the competition! However, due to the ever-changing landscape, navigating the myriad of digital marketing methods available can be difficult. In this post, we'll look at ten tried-and-true marketing methods that can help you increase your online presence and achieve tangible benefits for your brand.",
  categoryHref: '/blog',
  href: '/blog/19',
},
{
  thumb: '/images/blog/theArtOf_2_11zon.png',
  title: 'The Art of Social Media Strategy Development',
  subtitle:
  "Modern businesses want to reach customers, raise their brand's profile, and encourage content participation. But to do this, they must have a good social media strategy. The social media landscape is constantly changing, so developing a plan that people will remember is important. This blog entry will examine the key parts of an effective social media strategy. ",
  categoryHref: '/blog',
  href: '/blog/20',
},
{
  thumb: '/images/blog/video_marketing_20_11zon.png',
  title: 'Unlocking the Potential of Video Marketing: A Comprehensive Guide',
  subtitle:
  "Today, video marketing has become an effective way for businesses to interact with their target audience! It helps them increase engagement and raise brand exposure. With the rise of social media platforms and the proliferation of video content, using the potential of video marketing has become critical for businesses trying to stay ahead of the competition. In this post, we'll look at the many strategies that organizations may use. They aim to maximize the potential of video marketing.",
  categoryHref: '/blog',
  href: '/blog/21',
},
// /Users/digitalflakeshubhamp/Desktop/video_marketing.png

];

export default function BlogPage() {
  pageTitle('Blog');
  

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Adjusted to 6 posts per page

  // Calculate the indexes for slicing the postData array
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = postData.slice(indexOfFirstPost, indexOfLastPost);

  // Total number of pages
  const totalPages = Math.ceil(postData.length / itemsPerPage);

  // Function to change the page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container mx-auto">
        <Div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentPosts.map((post, index) => (
            <PostStyle2
              key={index}
              thumb={post.thumb}
              title={post.title}
              subtitle={post.subtitle}
              categoryHref={post.categoryHref}
              href={post.href}
            />
          ))}
        </Div>
        <Spacing lg="60" md="40" />
        <Pagination 
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
      </Div>
      {/* <Spacing lg="150" md="80" /> */}
      <Div className="container mx-auto mt-9">
        <Cta
          title="Let’s discuss and make <br />something <i>cool</i> together"
          btnText="Book a call"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
