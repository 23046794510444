import React from "react";
import parse from "html-react-parser";
import "./hero.scss";
import Div from "../Div";
import VerticalLinks from "../VerticalLinks";
import { Link } from "react-router-dom";

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
<Div className="cs-shape_1" />
<Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <div className="filter drop-shadow-2xl">
          <img
            src="images/PJPLogo_with_name.png"
            alt="Logo"
            className="logo-mobile md:hidden filter drop-shadow-2xl  "
            loading="lazy"
          />
          </div>
        
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <Div className="cs-hero_info">
            <Div className="flex justify-center items-center">
              <div className="relative inline-block">
                <Link to={btnLink}>
                  <button className="bg-[#257cd9] relative px-3 py-2 rounded-md w-full text-white">
                    {btnText}
                  </button>
                </Link>
                <div className="bg-green-400 w-4 h-4 absolute -right-1 -top-1 delay-75  animate-ping rounded-full"></div>
              </div>
            </Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
