import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";

import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

export default function AboutPage() {
  pageTitle("About");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your reliable digital marketing partner"
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                PJP Digital, a top digital agency in Pune since 2019, is at your
                service when building influential businesses that rule the
                digital space. We prioritize quality over quantity and aim to
                provide our most important clients with a measurable return on
                investment (ROI) for every campaign we handle.
              </p>
              <p className="cs-m0">
                We are committed to helping your business stand out in the
                digital world so you can get the recognition and success you
                need in this cutthroat industry. Whether you're a new firm
                trying to build your name or an old one trying to improve your
                online strategy, we're here to assist you.{" "}
              </p>

              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/social-media-marketing-concept-marketing-with-applications2.jpg"
              alt="About"
              className="w-100 cs-radius_15"
              loading="lazy"
            />
          </Div>
        </Div>
      </Div>
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      {/* <Div className="container">
        <FunFact
            title="Interesting Tidbit About us"
            subtitle="We are committed to helping your business stand out in the digital world so you can get the recognition and success you need in this cutthroat industry. Whether you're a new firm trying to build your name or an old one trying to improve your online strategy, we're here to assist you."
            data={funfaceData}
        />
      </Div> */}
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container  ">
        <Div className="row flex flex-wrap-reverse">
          <Div className="col-xl-5 col-lg-7">
            <Div className="col-lg-5  w-full h-full">
              <img
                src="/images/abt2.webp"
                alt="About"
                className="w-100 cs-radius_15 object-contain w-full h-full"
                loading="lazy"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-5">
            <SectionHeading
              title="Highly experienced pepole with us"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                In today's competitive environment, we recognize the importance
                of digital marketing. We personalize our services based on our
                client's specific demands and objectives to guarantee that we
                meet or surpass their expectations.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Whether you're a startup trying to build your brand or an
                established business trying to improve your digital strategy, we
                support your success.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}

      {/* End Team Section */}

      {/* Start CTA Section */}
      {/* <Spacing lg="150" md="80" /> */}

      <Div className="container mt-9">
        <Cta
          title="Together, let's Create, <br> Connect and build."
          btnText="Book a call"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
