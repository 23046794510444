import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import ReactSelect from "react-select";
import { customStyles, services } from "../../data";
import { useLocation, useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";

export default function ContactPage() {
  pageTitle("Contact Us");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    selectedServices: [],
  });

  const [loading, setLoading] = useState(false); // Loading state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const location = useLocation();
  // console.log("url ",location)
  // const gtag_report_conversion = (url) => {
  //   console.log("incomming url ",url)
  //   var callback = function () {
  //     if (typeof url != "undefined") {
  //       window.location = url;
  //     }
  //   };
  //   window.gtag("event", "conversion", {
  //     send_to: "AW-11376538882/OGpcCIH0jNoZEILq4LAq",
  //     value: 1.0,
  //     currency: "INR",
  //     event_callback: callback,
  //   });
  //   return false;
  // };

  
  const sendEmail = () => {
    setLoading(true); // Start loading
    const emailData = {
      from_name: formData.fullName,
      mobile: formData.mobile,
      to_name: "PJP Digital", // Adjust this based on your recipient
      message: `${formData.selectedServices
        .map((service) => service.label)
        .join(", ")}.`,
    };
    emailjs
      .send(
        "service_nnmhovp",
        "template_kpluxyf",
        emailData,
        "_3ewje7MNydvt-Qo8"
      )
      // .send(
      //   "service_38r8qbr", // Replace with your EmailJS service ID
      //   "template_0vqgxqu", // Replace with your EmailJS template ID
      //   emailData,
      //   "4pelo1DoRbwjQMTQs" // Replace with your EmailJS public key
      // )
      .then(
        (response) => {
          // Clear form and reset state only after successful submission
          setFormData({
            fullName: "",
            mobile: "",
            selectedServices: [],
          });
          setErrors({}); // Clear errors if any
          setLoading(false); // Stop loading
          // Report conversion here after successful submission
          // gtag_report_conversion("/thank-you");
          navigate("/thank-you");
        },
        (error) => {
          // console.log("Errror ",error)
          setErrorMessage("Failed to send the email. Please try again."); // Set error message
          setLoading(false); // Stop loading
          setErrors({}); // Optionally handle errors if necessary
        }
      );
  };

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Handling input changes and removing error messages dynamically
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Remove error message if field is valid
    if (value) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  // Handling service change for multi-select and removing error messages dynamically
  const handleServiceChange = (selectedOptions) => {
    setFormData({
      ...formData,
      selectedServices: selectedOptions || [],
    });

    // Remove error message if at least one service is selected
    if (selectedOptions.length > 0) {
      setErrors({
        ...errors,
        selectedServices: "",
      });
    }
  };

  // Validate fields
  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.selectedServices.length)
      newErrors.selectedServices = "Please select at least one service";
    if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    setErrors(newErrors);

    // Returns true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      sendEmail();

    } else {
      setErrorMessage("Validation failed")
      console.log("Validation failed");
    }
  };

  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Schedule Your Strategy <br/> Session Today!"
              subtitle="Getting Touch"
            />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="row">
              <Div className="col-sm-12">
                <label className="cs-primary_color">
                  Full Name <span className="background-red">*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="John Doe"
                  className={`cs-form_field ${
                    errors.fullName ? "error-border" : ""
                  }`}
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errors.fullName}
                  </p>
                )}
                <Spacing lg="20" md="20" />
              </Div>

              <Div className="col-sm-12">
                <label className="cs-primary_color">
                  Services Needed <span className="background-red">*</span>
                </label>
                <ReactSelect
                  isMulti
                  options={services.map((service) => ({
                    value: service.value,
                    label: service.title,
                  }))}
                  onChange={handleServiceChange}
                  value={formData.selectedServices}
                  styles={customStyles}
                  className={`${
                    errors.selectedServices ? "error-border" : ""
                  } z-50`}
                />
                {errors.selectedServices && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errors.selectedServices}
                  </p>
                )}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">
                  Mobile <span className="background-red">*</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  placeholder="9090909090"
                  className={`cs-form_field ${
                    errors.mobile ? "error-border" : ""
                  }`}
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {errors.mobile && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errors.mobile}
                  </p>
                )}
                <Spacing lg="20" md="20" />
              </Div>

              <Div className="col-sm-12">
                <button
                  type="submit"
                  className="cs-btn cs-style1"
                  disabled={loading}
                  onClick={(e) => handleSubmit(e)}
                >
                  <span>{loading ? "Sending..." : "Send Message"}</span>
                  {loading && <Icon icon="bi:arrow-right" />}
                </button>
                {errorMessage && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errorMessage}
                  </p>
                )}
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3784.0797803131823!2d73.8632497!3d18.4800451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebb23a4af40d%3A0x5688f96de21a02!2sPJP%20Digital%20-%20Digital%20Marketing%20Agency%20in%20Bibvewadi%2C%20Pune!5e0!3m2!1sen!2sin!4v1723119500491!5m2!1sen!2sin"
          allowFullScreen
          loading="lazy"
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
